import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const newsletter = 'https://shilohbridal.pmpframe.com/api/upload/newsletter.svg';

const Newsletter = () => {
  return (
    <div className="container-fluid my-4 px-5">
      <style>
        {`
          .custom-placeholder::placeholder {
            color: white;
            opacity: 1; 
          }
        `}
      </style>
      <div className="row align-items-center" style={{ backgroundColor: '#8E764F' }}>
        <div className="col-12 col-md-6 mb-3 mb-md-0 text-center text-md-left " style={{padding: "6rem"}}>
          <h2 className="mb-3" style={{ fontSize: '40px', fontFamily: 'Playfair Display', color: '#ffffff', fontWeight: "600" }}>
            Subscribe to Our Newsletter
          </h2>
          <input
            type="email"
            className="form-control mb-3 custom-placeholder"
            placeholder="Email"
            style={{ padding: '10px', borderColor: '#ffffff', borderRadius: '8px', backgroundColor: "#8E764F", fontSize: "20px", fontWeight: "400", fontFamily: "ubuntu" }}
          />
          <p className="text-white mb-3 p-0">Join Our Bridal Community for Special Updates and Style Guides</p>
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: '#12121285',
              color: '#ffffff',
              border: 'none',
              borderRadius: '4px',
              padding: '10px 20px',
              fontWeight: "700",
              fontSize: "16px",
            }}
          >
            Subscribe
          </button>
        </div>
        <div className="col-12 col-md-6 text-center p-0">
          <img
            src={newsletter}
            alt="Newsletter"
            className="img-fluid rounded"
            style={{ maxWidth: '100%', height: 'auto', width: '878px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
