import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import NavHeading from '../Navheading/NavHeading';
import Navbar from '../Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default function Address() {
  return (
    <div>
      <NavHeading />
      <Navbar />
      <Container fluid className="dashboard-container">
        <h1 className="py-4" style={{ textAlign: "center", color: "#121212BF" }}>My Account</h1>
        <Row className="dashboard">
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Row className="g-4 px-5">
              {/* Saved Addresses Heading and Button */}
              <Col md={12} className="d-flex justify-content-between align-items-center">
                <h2 style={{ color: "#121212BF" }}>Saved Addresses</h2>
                <Button
                  variant="outline px-5 py-2"
                  style={{
                    backgroundColor: "#ffffff",
                    border: "2px solid #8E1336",
                    color: "#8E1336",
                    fontSize: "15px",
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" /> Add New Address
                </Button>
              </Col>

              {/* Default Address Heading */}
              <Col md={12}>
                <h2 style={{ color: "#121212BF" }}>Default Address</h2>

                <Card className="d-flex p-4 px-5 shadow-sm">
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <h5 style={{ color: "#121212BF" }}>xyz</h5>
                    <Button
                      variant="outline"
                      style={{
                        backgroundColor: "#E6E5E5",
                        color: "#000000",
                        fontSize: "15px",
                        borderRadius: "21px",
                      }}
                    >
                      Home
                    </Button>
                  </div>
                  <div className="mt-3">
                    <h5 style={{ color: "#121212BF" }}>Address: A- 123 address</h5>
                    <h5 style={{ color: "#121212BF" }}>City & Pincode: ABC - 123456</h5>
                    <h5 style={{ color: "#121212BF" }}>State: A1B2C3</h5>
                  </div>
                  <div className="mt-3">
                    <h5 style={{ color: "#121212BF" }}>Mobile No.</h5>
                  </div>
                  <div className="d-flex justify-content-end mt-3" style={{ marginTop: 'auto' }}>
                    <Button
                      variant="outline px-5 py-2 m-1"
                      style={{
                        backgroundColor: "#ffffff",
                        border: "2px solid #8E1336",
                        color: "#8E1336",
                        fontSize: "15px",
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outline px-5 py-2 m-1"
                      style={{
                        backgroundColor: "#ffffff",
                        border: "2px solid #8E1336",
                        color: "#8E1336",
                        fontSize: "15px",
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
