import React, { useEffect, useState } from 'react';
import Related from '../ClotheMenu/Related';
import SizeGuide from '../SizeGuide/SizeGuide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import QtyInput from '../Quantity/Quantity';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const custom = 'https://shilohbridal.pmpframe.com/api/upload/custom_measurment/custom measurements.svg';

export default function ProductDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mainImage, setMainImage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [customMeasurement, setCustomMeasurement] = useState(false);
  const [formData, setFormData] = useState({
    bust: '',
    waist: '',
    hips: '',
    height: '',
  });

  const handleImageClick = (imageSrc) => {
    setMainImage(imageSrc);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleQuantityChange = (value) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + value));
  };

  const handleCustomMeasurementChange = (e) => {
    setCustomMeasurement(e.target.checked);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchProductDetails = async () => {
    try {
      const formDataObj = new FormData();
      formDataObj.append('action', 'product_detail_list');
      formDataObj.append('id', id);

      const response = await fetch('https://shilohbridal.pmpframe.com/api/product.php', {
        method: 'POST',
        body: formDataObj,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.success) {
        setProductData(result.data[0]);
        setMainImage(result.data[0].url);
      } else {
        console.error('API call failed:', result.message);
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const userId = localStorage.getItem('user_id'); // Check for user login status
    if (!userId) {
      alert("Please log in to add items to your cart.");
      navigate('/Signin'); // Redirect to login if not logged in
      return;
    }
  
    try {
      const formDataObj = new FormData();
      formDataObj.append('action', 'cart_add');
      formDataObj.append('user_id', userId); // User ID from local storage
      formDataObj.append('itemcode', productData.itemcode);
      formDataObj.append('quantity', quantity);
  
      if (customMeasurement) {
        formDataObj.append('bust', formData.bust);
        formDataObj.append('waist', formData.waist);
        formDataObj.append('hips', formData.hips);
        formDataObj.append('height', formData.height);
      }
  
      const response = await fetch('https://shilohbridal.pmpframe.com/api/cart.php', {
        method: 'POST',
        body: formDataObj,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Add to Cart Response:', result);
  
      if (result.success) {
        alert('Item added to cart successfully!');
      } else {
        alert(`Failed to add item to cart: ${result.message}`);
      }
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };
  

  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (!productData) return <div>Product not found</div>;

  return (
    <div className="container-fluid my-5 p-5">
      <div className="row">
        <div className="col-md-5">
          <div className="row">
            <div className="col-3">
              <img
                className="img-fluid mb-2 rounded"
                src={productData.url}
                alt="preview"
                onClick={() => handleImageClick(productData.url)}
                style={{ height: '100px', width: '100%', objectFit: 'cover' }} 
              />
              <img
                className="img-fluid rounded"
                src={productData.url1}
                alt="preview"
                onClick={() => handleImageClick(productData.url1)}
                style={{ height: '100px', width: '100%', objectFit: 'cover' }} 
              />
            </div>

            <div className="col-9">
              <img className="img-fluid w-100" src={mainImage} alt="Main" />
            </div>
          </div>
        </div>

        <div className="col-md-7">
          <h4 className="p-2" style={{fontSize: "32px", fontFamily: "playfair display", fontWeight: "500", color: "#121212BF"}}>{productData.name}</h4>
          <div className="p-2" style={{fontSize: "20px", fontFamily: "ubuntu", color: "#121212BF", fontWeight: "400"}}>#{productData.itemcode}</div>
          <div className="my-2">
            <span className="text p-2" style={{fontSize: "20px" , color: "#8E764F"}}>★★★★☆</span> <span style={{fontSize: "20px"}}>(18)</span>
          </div>
          <div className="my-2 p-2" style={{fontSize: "20px",fontFamily: "ubuntu", fontWeight: "500", color: "#121212Bf"}}>
            <strike className="text-muted" style={{fontSize: "16px",fontFamily: "ubuntu", fontWeight: "400", color: "#888888"}}>RS. {parseInt(productData.price) + parseInt(productData.discount)}</strike> RS. {productData.price}
          </div>
          <div className="my-2 p-2" style={{ fontSize: "24px", color: "#121212Bf" }}>
            Color : 
            <span className="text mx-1"  style={{ fontSize: "20px", color: "#8E1336" }}>
              {productData.color || 'Black'}
            </span>
            <div 
              style={{
                width: '31px',  
                height: '31px', 
                borderRadius: '50%', 
                backgroundColor: 'black', 
                marginTop: '5px', 
                border: "1px solid #121212BF",
              }} 
            />
          </div>

          <div className="d-flex flex-column my-2 p-2">
            <div className="d-flex align-items-center">
              <i className="bi bi-ruler" style={{ fontSize: '20px', marginRight: '5px' }}></i>
              <label className="me-2">Size:</label>
              <span
                className="ms-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={toggleModal}
              >
                Size Guide
              </span>
            </div>
            <div className="mt-2">
              <select className="form-select " style={{width: "184px"}} >
                <option>Choose Your Size</option>
                <option>US 0 / UK 2 / EU 30</option>
                <option>US 2 / UK 6 / EU 32</option>
                <option>US 4 / UK 8 / EU 34</option>
                {/* Additional size options */}
              </select>
            </div>
          </div>

          <div className="d-flex align-items-center my-2 p-2">
            <label className="me-2">Quantity:</label>
            <QtyInput/ >
            {/* <QtyInput quantity={quantity} onQuantityChange={handleQuantityChange} /> */}
          </div>

          <div className="form-check p-2">
            <input className="form-check-input" type="checkbox" id="customMeasurementCheckbox" checked={customMeasurement} onChange={handleCustomMeasurementChange} />
            <label className="form-check-label" htmlFor="customMeasurementCheckbox">Custom Measurement</label>
          </div>

          {customMeasurement && (
            <div className="row">
              <div className="col-md-6">
                <h6>Fill in your details for tailor-made fit (INR Rs. 20 fee applies).</h6>
                <div className="mb-3">
                  <label>Bust (Inch)</label>
                  <select className="form-select" name="bust" value={formData.bust} onChange={handleFormChange}>
                    <option value="">Select Bust</option>
                    <option value="32">32</option>
                    <option value="34">34</option>
                  </select>
                </div>
              
              </div>
              <div className="col-md-6 text-center">
                <img src={custom} alt="Tailor" className="img-fluid" />
              </div>
            </div>
          )}

       
<div className="d-flex p-2">
<button
        className="btn btn-outline me-2 px-3 py-2"
        onClick={handleSubmit}
        style={{
          marginTop: "0px",
          backgroundColor: "white",
          color: "#8E1336",
          borderColor: "#8E1336"
        }}
      >
        Add to Cart
      </button>
      <button className="border-0" style={{ background: "none" }}>
        <FontAwesomeIcon icon={faHeart} style={{ color: "red" , fontSize: "27px"}} /> {/* Set heart icon color to red */}
      </button>
    </div>
          

          <div className="">
          <h2 style={{color: "#121212BF", fontFamily: "ubuntu", fontWeight: "400"}}>Enter The Pincode</h2>
            <button className="btn btn-outline p-2 m-0">
           
              <FontAwesomeIcon icon={faLocationDot} /> Deliver To Xyz - India 012345
            </button>
          </div>

          <div className="delivery-description my-2">
        Delivery by: <strong>Sunday, 01 October</strong>
      </div>

      <div className="headings-container row px-2 py-3">
        <div className="col-md-6 ">
          <div className="heading-section">
            <h4 className="heading-title">Product Details</h4>
            <p className="heading-description">Product Description:</p>
            <p className="heading-description" style={{ marginTop: '35px' }}>Material & Care:</p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="heading-section">
            <h4 className="heading-title">Size & Fit</h4>
            <p className="heading-description">{productData.description}</p>
            <p className="heading-description" style={{ marginTop: '35px' }}>Material & Care: Soft Net, Dry Clean Only</p>
          </div>
        </div>
      </div>

      {/* <div className="related-products details-snippet1 mt-4">
        <div className="related-heading theme-text">
          <h5>Related Products</h5>
        </div>
        
      </div>
       */}
        </div>
        <div className="row">
          {/* <Related /> */}
        </div>
      </div>

    

      {isModalOpen && <SizeGuide closeModal={toggleModal} />}
    </div>
  );
}
