import React from 'react';
import NavBar from '../components/Navbar/Navbar';
import NavHeading from '../components/Navheading/NavHeading';
// import Hero from '../components/Hero/Hero';
import Sidebar from '../components/Sidebar/Sidebar';
import Dashboard from '../components/Dashboard/Dashboard';
import { Container, Row, Col } from 'react-bootstrap';


export default function Account() {
  return (
    <div>
      <NavHeading />
     <NavBar />
     {/* <Hero /> */}
   
    <Container fluid className="dashboard-container">
    <h1  className="py-4" style={{textAlign: "center"}}>My Account</h1>
      <Row className="dashboard">
      
        <Col md={2}>
          <Sidebar />
        </Col>
        <Col md={10}>
        
          <Dashboard />
        </Col>
      </Row>
    </Container>
 
    </div>
  )
}
