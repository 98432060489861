import React from 'react';
import Registration from '../components/Registration/Registration';

export default function Signup() {
  return (
    <div>
      <Registration />
    </div>
  )
}
