import React, { useState, useEffect } from 'react';
import SizeGuide from '../SizeGuide/SizeGuide';
import QtyInput from '../Quantity/Quantity';

const delivery = 'https://shilohbridal.pmpframe.com/api/upload/delivery.png';
const arrow = 'https://shilohbridal.pmpframe.com/api/upload/arrow.png';
const uplevel = 'https://shilohbridal.pmpframe.com/api/upload/uplevel.png';
const measure = 'https://shilohbridal.pmpframe.com/api/upload/measure.png';

export default function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [couponCode, setCouponCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sizes = [
    "US 0 / UK 2 / EU 30", "US 2 / UK 6 / EU 32", "US 4 / UK 8 / EU 34",
    "US 6 / UK 10 / EU 36", "US 8 / UK 12 / EU 38", "US 10 / UK 14 / EU 40",
    "US 12 / UK 16 / EU 42", "US 14 / UK 18 / EU 44", "US 16 / UK 20 / EU 46",
    "US 16W / UK 20 / EU 46", "US 18 / UK 22 / EU 48", "US 20 / UK 24 / EU 50"
  ];
  const [selectedSize, setSelectedSize] = useState("US 2 / UK 6 / EU 32");

  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const userId = localStorage.getItem('user_id'); // Fetch user_id from localStorage

        if (!userId) {
          console.error('User is not logged in.');
          return;
        }

        const formData = new FormData();
        formData.append('action', 'cart_list');
        formData.append('user_id', userId);

        const response = await fetch('https://shilohbridal.pmpframe.com/api/cart.php', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.success) {
          setCartItems(data.cart);
        } else {
          console.error('Failed to fetch cart data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };

    fetchCartData();
  }, []);

  const handleApplyCoupon = () => {
    console.log("Coupon Code:", couponCode);
    // Implement coupon application logic
  };

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const handleSize = (event) => {
    setSelectedSize(event.target.value);
  };

  return (
    <div className="container">
      <h2 className="mb-4" style={{ fontSize: '1.7rem', fontFamily: 'initial', fontWeight: 'revert' }}>My Cart</h2>
      <div className="row">
        <div className="col-md-6">
          <div className="card my-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span>Check delivery time & services</span>
                <form className="w-50">
                  <input className="form-control" type="search" placeholder="Enter Your Address" />
                </form>
              </li>
            </ul>
          </div>

          <div className="card my-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span> 1/1 Item Selected</span>
              <h6 className='d-flex'>Remove</h6>
              <h6>Move to Wishlist</h6>
              </li>
            </ul>
          </div>

          {cartItems.length > 0 ? cartItems.map(item => (
            <div className="card my-3" key={item.id}>
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={item.url} className="img-fluid rounded-start p-3" alt={item.itemcode} />
                </div>
                <div className="col-md-8 position-relative">
                  <button type="button" className="btn-close position-absolute top-0 end-0 m-2" aria-label="Close"></button>
                  <div className="card-body">
                    <h5 className="card-title">
                      {item.name} <img src={uplevel} alt="uplevel" className="ms-2" style={{ verticalAlign: 'middle' }} />
                    </h5>
                    <p className="mb-3">
                      <strike className="text-muted">RS. {item.price}</strike>
                      <span className="ms-2 text-danger">Rs. {item.discount}</span>
                    </p>
                    <p className="text-primary mb-3" style={{ cursor: 'pointer' }} onClick={toggleModal}>
                      <img src={measure} alt="size guide" className="me-2" style={{ width: "24px", height: "17px" }}/>Size Guide
                    </p>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <label htmlFor="size-select" className="me-2">Size:</label>
                        <select id="size-select" className="form-select" value={selectedSize} onChange={handleSize} style={{ width: '100px' }}>
                          {sizes.map(size => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                      </div>
                      <QtyInput />
                    </div>
                    <p className="mt-3"><img src={arrow} alt="arrow" /> 14 Days return available</p>
                  </div>
                </div>
              </div>
            </div>
          )) : (
            <div className="alert alert-warning">Your cart is empty.</div>
          )}
        </div>

        <div className="col-md-6">
          <div className="card my-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex align-items-center">
                <input type="checkbox" className="me-2" />
                <img src={delivery} alt="delivery" className="me-2" style={{ width: '24px' }} />
                <label className="me-2">Express Delivery</label>
                <span className="text-muted">Know More</span>
              </li>
            </ul>
          </div>

          <div className="card my-3">
            <div className="card-body">
              <h5>Summary ({cartItems.length} Item{cartItems.length > 1 ? 's' : ''})</h5>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total MRP</span><span>Rs. 4099</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Discount on MRP</span><span>- Rs. 630</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Coupon Discount</span><span className="text-danger">- Rs. 100</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Shipping Fee</span><span className="text-success">FREE</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Express Delivery Fee</span>
                </li>
                <li className="list-group-item d-flex justify-content-between fw-bold">
                  <span>Total Amount</span><span>Rs. 3469</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="card my-3">
            <div className="card-body">
              <p>Have a coupon? Enter your code</p>
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Coupon Code" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                <button className="btn btn" onClick={handleApplyCoupon}>Apply</button>
              </div>
            </div>
          </div>

          <div className="text-center">
            <button type="button" className="btn btn p-2 mx-2" style={{backgroundColor: "#8F1336", fontSize: "16px"}}>Continue to Checkout</button>
          </div>
        </div>
      </div>
      {isModalOpen && <SizeGuide closeModal={toggleModal} />}
    </div>
  );
}
