import React from 'react';
import './Gallery.css';

const Image11 = 'https://shilohbridal.pmpframe.com/api/upload/Img11.svg';
const Image12 = 'https://shilohbridal.pmpframe.com/api/upload/Img12.svg';
const Image13 = 'https://shilohbridal.pmpframe.com/api/upload/Img13.svg';
const Image14 = 'https://shilohbridal.pmpframe.com/api/upload/Img14.svg';
const Image15 = 'https://shilohbridal.pmpframe.com/api/upload/Img15.svg';
const Image16 = 'https://shilohbridal.pmpframe.com/api/upload/Img16.svg';
const Image17 = 'https://shilohbridal.pmpframe.com/api/upload/Img17.svg';

const itemData = [
  { img: Image11, title: 'Image 1' },
  { img: Image12, title: 'Image 2' },
  { img: Image13, title: 'Image 3' },
  { img: Image14, title: 'Image 4' },
  { img: Image15, title: 'Image 5' },
  { img: Image16, title: 'Image 6' },
  { img: Image17, title: 'Image 7' },
  { img: Image11, title: 'Image 1' },
  { img: Image12, title: 'Image 2' },
  { img: Image13, title: 'Image 3' },
];

const Gallery = () => {
  return (
    <div className="container-fluid px-5 mt-5">
      <h2 className="gallery-title">Gallery</h2>
      <div className="image-list">
        {itemData.map((item) => (
          <div className="image-item" key={item.img}>
            <img src={item.img} alt={item.title} className="gallery-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
