import React from 'react';
import Login from '../components/Login/Login';
// import NavHeading from '../components/Navheading/NavHeading';
// import NavBar from '../components/Navbar/Navbar';

export default function Singin() {
  return (
    <div>
     
      <Login />
    </div>
  )
}
