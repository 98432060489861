import React, { useState } from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

// Reusable CustomerCard component
const CustomerCard = ({ image }) => {
  return (
    <div
      className="customer-card text-center"
      style={{ transition: 'transform 0.3s ease' }}
    >
      <img
        src={image}
        alt="Customer"
        className="rounded-circle mb-2"
        style={{ width: '100px', height: '100px' }}
      />
    </div>
  );
};

const CardName = ({ name }) => {
  return (
    <div className="customer-name">
      <h5 className="" style={{fontSize: "24px", fontWeight: "700", fontFamily: "ubuntu"}}>{name}</h5>
    </div>
  );
};

// Reusable StarRating component
const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  return (
    <div className="stars" style={{ color: '#8E764F' }}>
      {[...Array(fullStars)].map((_, index) => (
        <FaStar key={index} />
      ))}
      {halfStar && <FaStarHalfAlt />}
    </div>
  );
};

// Main Customer component
const Customer = () => {
  const customers = [
    { image: 'https://shilohbridal.pmpframe.com/api/upload/Lorem.svg', name: 'Lorem ipsum', rating: 4.5 },
    { image: 'https://shilohbridal.pmpframe.com/api/upload/Lorem2.svg', name: 'Lorem ipsum 2', rating: 5 },
    { image: 'https://shilohbridal.pmpframe.com/api/upload/Lorem3.svg', name: 'Lorem ipsum 3', rating: 4.5 },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="testimonials-section container-fluid text-center my-5 px-5">
      <h2 className="mb-4" style={{textAlign: "left", fontSize: "40px", color: "#121212BF", fontFamily: "playfair display", fontWeight: "600"}}>Hear From Our Customers</h2>
      <div className="testimonials-container overflow-hidden">
        <div
          className="row"
          style={{
            transform: `translateX(-${currentIndex * 33.33}%)`,
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {customers.map((customer, index) => (
            <div key={index} className="col-12 col-md-4 mb-4">
              <div
                className="slide p-3 border rounded shadow-sm"
                style={{
                  minWidth: '32.33%',
                  padding: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  margin: '6px',
                  transition: 'transform 0.3s ease',
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
              >
                <CustomerCard image={customer.image} />
                <StarRating rating={customer.rating} />
                <div className="testimonial-detail mt-3" style={{color: "#121212BF"}}>
                  <p >Lorem ipsum dolor sit amet consectetur. Magna sit cursus sed tellus mauris. Metus cras molestie sapien in bibendum malesuada.</p>
                  <CardName name={customer.name}  />
                  <p>Married on 01/01/2024</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-buttons d-flex justify-content-center mt-1">
        {customers.map((_, index) => (
          <button
            key={index}
            className={`carousel-btn btn rounded-circle mx-1 p-2 ${currentIndex === index ? 'bg-danger' : 'bg-secondary'}`}
            style={{  border: 'none', cursor: 'pointer', transition: 'background-color 0.3s ease' }}
            onClick={() => handleNext(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Customer;
