import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const blogData = [
  {
    id: 1,
    img: 'https://shilohbridal.pmpframe.com/api/upload/blog1.svg',
    date: 'June 24, 2024',
    text: 'How to Accessorize Your Wedding Dress: Veils, Shoes, and Jewelry Tips',
  },
  {
    id: 2,
    img: 'https://shilohbridal.pmpframe.com/api/upload/blog2.svg',
    date: 'Aug 26, 2024',
    text: 'A Guide to Bridesmaid Dresses: Coordinating Styles and Colors',
  },
];

export default function Blog() {
  return (
    <div className="container-fluid my-5 px-5">
      <h2 className=" mb-4" style={{ fontSize: '40px', fontFamily: 'Playfair Display', color: '#121212BF', fontWeight: "600" }}>Our Blog</h2>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {blogData.map((blog) => (
          <div className="col" key={blog.id}>
            <div className="card h-100 border-0 shadow-sm">
              <img src={blog.img} className="card-img-top" alt={`Blog ${blog.id}`} style={{ maxWidth: '100%', height: 'auto' }} />
              <div className="card-body py-3 px-0">
                <p className="card-date mb-2" style={{ fontSize: '20px', fontFamily: 'Ubuntu' , color:  "#121212BF"}}>{blog.date}</p>
                <p className="card-text" style={{ fontSize: '24px', fontFamily: 'Ubuntu' , color:  "#121212BF"}}>{blog.text}</p>
                <button type="button" className="btn btn-blog" style={{ fontSize: '16px', padding: '7px 18px', backgroundColor: '#8F1336', color: '#fff', fontFamily: 'Ubuntu', borderRadius: '3px', border: '1px solid #8E1336' , fontWeight: "700"}}>Read Full Blog</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-center mt-4">
        <button type="button" className="btn" style={{ fontSize: '16px', padding: '7px 18px', backgroundColor: '#ffffff', color: '#8F1336', fontFamily: 'Ubuntu', borderColor: '#8F1336', borderStyle: 'solid', borderRadius: '6px',  fontWeight: "700"}}>
          View All <span style={{ fontSize: '20px' }}>→</span>
        </button>
      </div>
    </div>
  );
}
