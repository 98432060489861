import React, { useState } from 'react';
import './SizeGuide.css'; 
const howToMeasure = 'https://shilohbridal.pmpframe.com/api/upload/custom_measurment/how to measure.svg';
const sizeChartCM = 'https://shilohbridal.pmpframe.com/api/upload/custom_measurment/size chart cm.svg';
const sizeChartIN = 'https://shilohbridal.pmpframe.com/api/upload/custom_measurment/size chart in.svg';

export default function SizeGuide({ closeModal }) {
  const [image, setImage] = useState(sizeChartIN); // Default to "IN" image

  const handleSizeClick = (sizeType) => {
    if (sizeType === 'IN') {
      setImage(sizeChartIN); // Show "IN" image
    } else if (sizeType === 'CM') {
      setImage(sizeChartCM); // Show "CM" image
    }
  };

  const handleMeasurementClick = () => {
    setImage(howToMeasure); // Show "How to Measure" image
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          {/* <h5 className="modal-title">Size Guide</h5> */}
          <button type="button" className="close" onClick={closeModal}>
            &times;
          </button>
        </div>

        <div className="modal-body">
          {/* First Heading: Size Guide and How to Measure on the same line */}
          <div className="guide-header">
            <h2>Size Guide</h2>
            <h2 onClick={handleMeasurementClick} style={{ cursor: 'pointer' }}>
              How to Measure
            </h2>
          </div>

          {/* Second Line: Size Chart and IN/CM buttons */}
          <div className="size-selection">
            <h2>Size Chart</h2>
            <div className="button-group">
              <button onClick={() => handleSizeClick('IN')} className="size-button">IN</button>
              <button onClick={() => handleSizeClick('CM')} className="size-button">CM</button>
            </div>
          </div>

          {/* Display Image */}
          {image && <img src={image} alt="Size Guide" style={{ width: '100%', marginTop: '20px' }} />}
        </div>
      </div>
    </div>
  );
}
