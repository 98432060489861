import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

const Black = "https://shilohbridal.pmpframe.com/api/upload/Black.svg";
const Teil = "https://shilohbridal.pmpframe.com/api/upload/Teil.svg";
const categoryvideo =
  "https://shilohbridal.pmpframe.com/api/upload/categoryvideo.svg";

// CategoryTabs component
const CategoryTabs = ({ categories, activeTab, onTabClick }) => {
  return (
    <div
      className="d-flex justify-content-start gap-3  px-2"
      style={{
        overflowX: "auto",
        paddingBottom: "20px",
      }}
    >
      {categories.map((category, index) => (
        <span
          key={index}
          className={`tab ${activeTab === category ? "active-tab" : ""}`}
          onClick={() => onTabClick(category)}
          style={{
            cursor: "pointer",
            padding: "10px 10px",
            borderRadius: "20px",
            fontSize: activeTab === category ? "24px" : "20px",
            color: activeTab === category ? "#8F1336" : "#121212BF",
            fontWeight: activeTab === category ? "bold" : "normal",
          }}
        >
          {category}
        </span>
      ))}
    </div>
  );
};

// ProductCard component
const ProductCard = ({ image, title }) => (
  <div
    className="product-card "
    style={{ aspectRatio: "4/", overflow: "hidden" }}
  >
    <img
      src={image}
      alt={title}
      className="img-fluid w-100 h-100 rounded"
      style={{ objectFit: "cover" }}
    />
    <p
      style={{
        fontSize: "32px",
        fontFamily: "Playfair Display",
        textAlign: "center",
        marginTop: "12px",
        color: "#121212BF",
        fontWeight: "500",
      }}
    >
      {title}
    </p>
  </div>
);

// VideoCard component
const VideoCard = ({ image, title }) => (
  <div
    className="video-card position-relative"
    style={{ aspectRatio: "16/", overflow: "hidden" }}
  >
    <img
      src={image}
      alt={title}
      className="img-fluid w-100 h-100"
      style={{ objectFit: "cover" }}
    />
  </div>
);

// Main ShopByCategories component
const ShopByCategories = () => {
  const categories = [
    "Cocktail Dress",
    "Formal Dress",
    "Evening Dress",
    "Veils",
  ];
  const [activeTab, setActiveTab] = useState(categories[0]);

  const handleTabClick = (category) => {
    setActiveTab(category);
  };

  return (
    <div className="container-fluid mt-4 px-5 w-100">
      <h2
        className="mb-4 mt-5 fw-600"
        style={{
          fontFamily: "Playfair Display",
          color: "#121212BF",
          fontSize: "40px",
          fontWeight: "600"
        }}
      >
        Shop By Categories
      </h2>
      <CategoryTabs
        categories={categories}
        activeTab={activeTab}
        onTabClick={handleTabClick}
      />

      <div className="row">
        <div className="col-12 col-md-4 col-lg-4 mb-4">
          <ProductCard image={Black} title="Black Frill Gown" />
        </div>
        <div className="col-12 col-md-4 col-lg-4 mb-4">
          <ProductCard image={Teil} title="Teal Fish-Cut Gown" />
        </div>
        <div className="col-12 col-md-4 mb-4 position-relative">
          <VideoCard image={categoryvideo} title="Red Gown Video" />
          <div
            className="d-flex justify-content-center position-absolute w-100"
            style={{
              bottom: "10px", 
              gap: "24px",
              right:"149%"
            }}
          >
            <button
              className="prev-button btn btn d-none d-md-block"
              style={{
                width: "44px",
                height: "44px",
                borderRadius: "50%",
                backgroundColor: "#C1C1C1",
              }}
            >
              <FaAngleLeft />
            </button>
            <button
              className="next-button btn btn-secondary d-none d-md-block"
              style={{
                width: "44px",
                height: "44px",
                borderRadius: "50%",
                backgroundColor: "#8E1336",
              }}
            >
              <FaAngleRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopByCategories;
