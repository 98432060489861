import React, { useState } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  // State to track the active item
  const [activeItem, setActiveItem] = useState('Overview');
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  // Function to handle button click and set active item
  const handleItemClick = (item) => {
    setActiveItem(item);
    switch (item) {
      case 'Overview':
        navigate('/Account');
        break;
      case 'Addresses':
        navigate('/Address'); 
        break;
      case 'Orders':
        navigate('/Orders'); 
        break;
      case 'Payment Methods':
        navigate('/PaymentMethods'); 
        break;
      case 'Personal Details':
        navigate('/PersonalDetails'); 
        break;
      case 'Log Out':
        // Handle logout logic here
        break;
      default:
        break;
    }
  };

  return (
    <Navbar expand="lg" className="flex-column p-0" style={{ padding: '0px 92px' }}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="flex-column">
          {['Overview', 'Addresses', 'Orders', 'Payment Methods', 'Personal Details', 'Log Out'].map((item, index) => (
            <Button 
              key={index} 
              variant="outline" 
              className={`my-2 ${activeItem === item ? 'active' : ''}`}
              style={{
                width: '187px',
                height: '51px',
                fontSize: "17px",
                border: "1px solid #000000",
                backgroundColor: activeItem === item ? "#8E1336" : "#ffffff", // Change color when active
                color: activeItem === item ? "#ffffff" : "#000000", // Change text color when active
              }}
              onClick={() => handleItemClick(item)} // Set active item on click and navigate
            >
              {item}
            </Button>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Sidebar;
