import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const QtyInput = () => {
  const [qty, setQty] = useState(1);
  const qtyMin = 1;
  const qtyMax = 100;

  const handleChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value) || value <= qtyMin) {
      setQty(qtyMin);
    } else if (value >= qtyMax) {
      setQty(qtyMax);
    } else {
      setQty(value);
    }
  };

  const handleClick = (operator) => {
    let newQty = qty;
    if (operator === "add") {
      newQty = qty < qtyMax ? qty + 1 : qtyMax;
    } else {
      newQty = qty > qtyMin ? qty - 1 : qtyMin;
    }
    setQty(newQty);
  };

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-auto">
          <div className="input-group">
            <button
              style={{
                marginBottom: "0px",
                marginTop: "0px",
                backgroundColor: qty <= qtyMin ? "#888888" : "#8E1336", // Grey when disabled, else the specified color
                color: "white"
              }}
              className="btn btn-outline"
              onClick={() => handleClick("minus")}
              disabled={qty <= qtyMin}
            >
              -
            </button>
            <input
              className="form-control text-center"
              // type="number"
              name="product-qty"
              
              min={qtyMin}
              max={qtyMax}
              value={qty}
              onChange={handleChange}
            />
            <button
              style={{
                marginBottom: "0px",
                marginTop: "0px",
                backgroundColor: qty >= qtyMax ? "#888888" : "#8E1336", // Grey when disabled, else the specified color
                color: "white"
              }}
              className="btn btn-outline"
              onClick={() => handleClick("add")}
              disabled={qty >= qtyMax}
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QtyInput;
