import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export default function NavHeading() {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-md-auto d-flex justify-content-center align-items-center p-2" 
             style={{ height: "49px", backgroundColor: "#ffffff" }}>
          <h2 className="mb-0" style={{ fontSize: "16px", color: "#8F1336", fontFamily: "Ubuntu" }}>
            Shipping Worldwide
          </h2>
        </div>
      </div>
    </div>
  );
}
