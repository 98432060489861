import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Banner() {
  return (
    <div className=" text-white text-center py-2" style={{backgroundColor: "#8E764F"}}>
      <p className="mb-0">2023silohbridals.com</p>
    </div>
  );
}

export default Banner;
