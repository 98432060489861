import React from 'react';
import '../components/Hero/Hero.css';
import ClotheMenu from '../components/ClotheMenu/ClotheMenu';
import Footer from '../components/Footer/Footer';
import Banner from '../components/Banner/Banner';
import NavHeading from '../components/Navheading/NavHeading';
import NavBar from '../components/Navbar/Navbar';
// import Hero from '../components/Hero/Hero';

export default function ShopByCategories() {
  return (
    <div>
     <NavHeading />
     <NavBar />
     {/* <Hero />  */}
     <ClotheMenu />
     <Footer />
     <Banner />
    </div>
  );

};