import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export const base_url = "https://shilohbridal.pmpframe.com/api/upload/";
const logo = base_url + "logo.svg";
const india = base_url + "india.svg";
const heart = base_url + "heart.png";
const shopping = base_url + "shopping-cart.png";

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (userId) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("Search Term:", searchTerm);
  };

  const handleCart = () => {
    navigate("/mycart");
  };

  const handleLogin = () => {
    navigate("/Signin");
  };

  const handleHomepage = () => {
    navigate("/");
  };

  const handleWishlist = () => {
    navigate("/wishlist");
  };

  const handleAccount = () => {
navigate("/Account");
  };

  const toggleNavbar = () => {
    setIsActive(!isActive);
  };

  const toggleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleUserDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    localStorage.removeItem("user_id");
    setIsLoggedIn(false);
    navigate("/"); // Redirect to the homepage after logout
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg navbar-light bg-white ${isActive ? "active" : ""}`}>
        <div className="container-fluid px-5">
          <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
            <span className="navbar-toggler-icon"></span>
          </button>

          <a className="navbar-brand mx-lg-0 mx-auto" href="/" onClick={handleHomepage}>
            <img src={logo} alt="Shiloh Bridal Logo" width="95" height="100" />
          </a>

          <div className={`collapse navbar-collapse ${isActive ? "show" : ""}`}>
            <div className="d-flex align-items-center w-100">
              <form onSubmit={handleSearchSubmit} className="d-none d-lg-flex ms-lg-0" style={{ margin: "0px" }}>
                <div className="input-group mx-5" style={{ border: "1px solid #121212BF", borderRadius: "4px" }}>
                  <span className="input-group-text bg-white">
                    <FaSearch style={{ color: "#8F1336" }} />
                  </span>
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{
                      width: "200px",
                      textAlign: "left",
                      fontFamily: "ubuntu",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "#121212BF",
                    }}
                  />
                </div>
              </form>

              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
                <li className="nav-item d-flex align-items-center me-3">
                  <img src={india} className="img-fluid me-3" alt="India" width="30" height="30" />
                  <span
                    style={{
                      wordSpacing: "0.5rem",
                      color: "#121212BF",
                      fontSize: "16px",
                      fontFamily: "ubuntu",
                      fontWeight: "700",
                    }}
                  >
                    EN | INR
                  </span>
                </li>
                <li className="nav-item me-3">
                  <Link className="nav-link" to="/wishlist" onClick={handleWishlist}>
                    <img src={heart} alt="Wishlist" width="24" />
                  </Link>
                </li>
                {isLoggedIn ? (
                  <li className="nav-item dropdown">
                    <div
                      className="nav-link d-flex align-items-center justify-content-center rounded-circle bg-dark text-white"
                      style={{ width: "40px", height: "40px", cursor: "pointer" }}
                      onClick={handleUserDropdown}
                    >
                      {userName.charAt(0).toUpperCase()}
                    </div>
                    {showDropdown && (
                        <ul class="dropdown-menu show p-1" style={{ position: "absolute", marginLeft: "-49px" }}>
                        <li><button onClick={handleLogout} className="dropdown-item">
                            Logout
                          </button></li>
                        <li><button onClick={handleAccount} className="dropdown-item">
                            Dashboard
                          </button></li>
                      
                      </ul>
                     
                    )}
                  </li>
                ) : (
                  <li className="nav-item me-3">
                    <Link className="nav-link" to="/Signin" onClick={handleLogin}>
                      <img src={base_url + "user.png"} alt="User" width="24" />
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link className="nav-link" to="/mycart" onClick={handleCart}>
                    <img src={shopping} alt="Cart" width="24" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid px-5 py-3">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto dropdown">
            <h2
              onClick={() => toggleDropdown(0)}
              className="d-flex align-items-center m-0"
              style={{ cursor: "pointer", fontSize: "18px", fontWeight: "400", color: "#121212BF" }}
            >
              Bridal Collection
              <FontAwesomeIcon icon={faAngleDown} className={`ms-2 ${activeIndex === 0 ? "rotate" : ""}`} />
            </h2>
            {activeIndex === 0 && (
              <ul className="dropdown-menu show p-3">
               <li>
                  <Link to="/ShopByCategories" className="text-dark text-decoration-none">
                    Formal Dress
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="col-auto dropdown">
            <h2
              onClick={() => toggleDropdown(1)}
              className="d-flex align-items-center m-0 "
              style={{ cursor: "pointer", fontSize: "18px", fontWeight: "400", color: "#121212BF" }}
            >
              Bride Maids Collections
              <FontAwesomeIcon icon={faAngleDown} className={`ms-2 ${activeIndex === 1 ? "rotate" : ""}`} />
            </h2>
            {activeIndex === 1 && (
              <ul className="dropdown-menu show p-3">
                 <li>
                  <Link to="/ShopByCategories" className="text-dark text-decoration-none">
                    Formal Dress
                  </Link>
                </li>
              </ul>
            )}
          </div>
          <div className="col-auto dropdown">
            <h2
              onClick={() => toggleDropdown(2)}
              className="d-flex align-items-center m-0 "
              style={{ cursor: "pointer", fontSize: "18px", fontWeight: "400", color: "#121212BF" }}
            >
              Shop By Occasion
              <FontAwesomeIcon icon={faAngleDown} className={`ms-2 ${activeIndex === 2 ? "rotate" : ""}`} />
            </h2>
            {activeIndex === 2 && (
              <ul className="dropdown-menu show p-3">
                <li>
                  <Link to="/ShopByCategories" className="text-dark text-decoration-none">
                    Formal Dress
                  </Link>
                </li>
                <li>Cocktail Dress</li>
                <li>Evening Dress</li>
                <li>Veils</li>
              </ul>
            )}
          </div>
          <div className="col-auto">
            <h2 className="m-0" style={{ cursor: "pointer", fontSize: "18px", fontWeight: "400", color: "#121212BF" }}>
              Special Costumes
            </h2>
          </div>
          <div className="col-auto">
            <h2 className="m-0" style={{ cursor: "pointer", fontSize: "18px", fontWeight: "400", color: "#121212BF" }}>
              Contact Us
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};
    

export default Navbar;
