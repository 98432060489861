import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

export default function PasswordReset() {
  const navigate = useNavigate();
  const logo = "https://shilohbridal.pmpframe.com/api/upload/logo.svg";

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/OTP');
  };

  return (
    <div className="container-forgotpassword d-flex align-items-center justify-content-end">
      <div className="form-2-wrapper bg-white p-4 rounded shadow-sm">
        <div className="text-center mb-4">
          <img src={logo} className="img-fluid" alt="Shiloh Bridal Logo" />
        </div>
        <h2 className="text-center mb-4">Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">
              Please Enter Your Email Address To Receive A Verification Code
            </label>
            <h6>Email OR Phone Number</h6>
            <input 
              type="email" 
              className="form-control" 
              placeholder="Enter your email or phone number" 
              required 
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}
