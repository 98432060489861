import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import NavHeading from '../Navheading/NavHeading';
import NavBar from '../Navbar/Navbar';

export default function PersonalDetails() {
return (
    <div>
    <NavHeading />
    <NavBar />
    <Container fluid className="dashboard-container">
        <h1 className="py-4" style={{ textAlign: "center", color: "#121212BF" }}>My Account</h1>
        <Row className="dashboard">
        <Col md={2}>
            <Sidebar />
        </Col>
        <Col md={10}>
            <Row className="g-4 px-5">
            <Col md={12} className="d-flex justify-content-between align-items-center">
                <h2 style={{ color: "#121212BF" }}>Personal Details</h2>
            </Col>

            {/* Card for Editing Personal Details */}
            <Col md={12}>
                <Card className="d-flex p-4 px-5 shadow-sm">
                <div className="d-flex justify-content-between w-100 align-items-center py-3">
                    <h4 style={{ color: "#121212BF" }}>Edit Details</h4>
                </div>

                
                    <Row className="mb-3">
                        <Col md={6}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter first name" />
                        </Form.Group>
                        </Col>
                        <Col md={6}>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter last name" />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                        </Form.Group>
                        </Col>
                        <Col md={6}>
                        <Form.Group controlId="formMobileNumber">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter mobile number" />
                        </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center">
                        <Button 
                        variant="outline px-5 py-2" 
                        style={{ backgroundColor: "#ffffff", border: "2px solid #8E1336", color: "#8E1336", fontSize: "15px" }}
                        >
                        Save
                        </Button>
                    </div>
                
                
                </Card>
            </Col>
            </Row>
        </Col>
        </Row>
    </Container>
    </div>
);
}
