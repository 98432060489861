import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const logo = 'https://shilohbridal.pmpframe.com/api/upload/logo.svg';

export default function Registration() {
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [fullname, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirm_password) {
      setError("Passwords do not match.");
      return;
    }

    const isEmail = /\S+@\S+\.\S+/.test(emailOrMobile);
    const isMobile = /^\d+$/.test(emailOrMobile);

    if (!isEmail && !isMobile) {
      setError("Please enter a valid email or mobile number.");
      return;
    }

    const formData = new FormData();
    formData.append('fullname', fullname);
    formData.append(isEmail ? 'email' : 'mobile', emailOrMobile);
    formData.append('password', password);
    formData.append('confirm_password', confirm_password);

    fetch('https://shilohbridal.pmpframe.com/api/registration.php', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert("Registration successful!");
          navigate('/signin');
        } else {
          setError(data.message || 'Something went wrong.');
        }
      })
      .catch(error => {
        setError('Failed to register. Please try again later.');
        console.error('Error:', error);
      });
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-end p-5" style={{ backgroundImage: `url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <div className="col-lg-4 col-md-6 col-sm-8">
        <div className="bg-white p-5 rounded shadow">
          <div className="text-center mb-4">
            <img src={logo} className="logo-image" alt="Logo" />
            <h2 className="mt-3">Sign Up</h2>
          </div>

          {error && <div className="alert alert-danger">{error}</div>}

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <h2>Full Name</h2>
              <input
                type="text"
                className="form-control py-2"
                id="fullname"
                placeholder=""
                value={fullname}
                onChange={(e) => setFullName(e.target.value)}
                required

                style={{border: "2px solid #405c7cb8", marginLeft: "0px"}}
              />
            </div>
            <div className="mb-3">
              <h2>Email or Phone Number</h2>
              <input
                type="text"
                className="form-control py-2"
                id="emailOrMobile"
                placeholder=""
                value={emailOrMobile}
                onChange={(e) => setEmailOrMobile(e.target.value)}
                required
                style={{border: "2px solid #405c7cb8", marginLeft: "0px"}}
              />
            </div>
            <div className="mb-3">
              <h2>Password</h2>
              <input
                type="password"
                className="form-control py-2"
                id="password"
                placeholder="At least 8 characters"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{border: "2px solid #405c7cb8", marginLeft: "0px"}}
              />
            </div>
            <div className="mb-3">
              <h2>Confirm Password</h2>
              <input
                type="password"
                className="form-control py-2"
                id="confirmPassword"
                placeholder=""
                value={confirm_password}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                style={{border: "2px solid #405c7cb8", marginLeft: "0px"}}
              />
            </div>
            
            <button type="submit" className="btn btn w-100 mb-3 p-2" style={{backgroundColor: "#8E1336", color: "white"}}>
              Sign Up
            </button>
          </form>

          <p className="text-center mt-3">
            Already have an account?{' '}
            <Link to="/signin" className="text-decoration-none">
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
