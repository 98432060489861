import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import NavHeading from '../Navheading/NavHeading';
import NavBar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faUniversity, faMoneyBillWave, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

export default function PaymentMethods() {
  const navigate = useNavigate();

  const handleAddClick = (paymentMethod) => {
    // Navigate to a different page, passing the payment method as a parameter
    navigate(`/${paymentMethod}`);
  };

  return (
    <div>
      <NavHeading />
      <NavBar />
      <Container fluid className="dashboard-container">
        <h1 className="py-4" style={{ textAlign: "center", color: "#121212BF" }}>My Account</h1>
        <Row className="dashboard">
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Row className="g-4 px-5">
              <Col md={12} className="d-flex justify-content-between align-items-center">
                <h2 style={{ color: "#121212BF" }}>Payment Methods</h2>
              </Col>

              {/* Main Card */}
              <Col md={8}>
                <Card className="d-flex p-4 px-5 shadow-sm">
                  <div className="d-flex justify-content-between w-100 align-items-center py-3">
                    <h4 style={{ color: "#121212BF" }}>Payment Methods</h4>
                  </div>

                  {/* Four Inner Cards */}
                  <Row className="mb-3" >
                    <Col md={12}>
                      <h2 className="py-2" style={{ fontSize: "24px", color: "#121212BF" }}>Cards</h2>
                      <Card className="mb-3 p-3 d-flex flex-row justify-content-between align-items-center" style={{boxShadow: "3px 3px 17px 2px #E6E5E5"}}>
                        <div>
                          <h5 style={{ color: "#121212BF" }}>
                            <FontAwesomeIcon icon={faCreditCard} className="me-2" style={{ color: "#8E764F" }} />
                            Credit or Debit Cards
                          </h5>
                        </div>
                        <Button
                          variant="outline-secondary"
                          style={{ backgroundColor: "#ffffff", border: "2px solid #8E1336", color: "#8E1336" }}
                          onClick={() => handleAddClick('CreditDebit')}
                        >
                          Add
                        </Button>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <h2 className="py-2" style={{ fontSize: "24px", color: "#121212BF" }}>UPI App</h2>
                      <Card className="mb-3 p-3 d-flex flex-row justify-content-between align-items-center" style={{boxShadow: "3px 3px 17px 2px #E6E5E5"}}>
                        <div>
                          <h5 style={{ color: "#121212BF" }}>
                            <FontAwesomeIcon icon={faMobileAlt} className="me-2" style={{ color: "#8E764F" }} />
                            New UPI ID
                          </h5>
                        </div>
                        <Button
                          variant="outline-secondary"
                          style={{ backgroundColor: "#ffffff", border: "2px solid #8E1336", color: "#8E1336" }}
                          onClick={() => handleAddClick('upi-app')}
                        >
                          Add
                        </Button>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <h2 className="py-2" style={{ fontSize: "24px", color: "#121212BF" }}>Net Banking</h2>
                      <Card className="mb-3 p-3 d-flex flex-row justify-content-between align-items-center" style={{boxShadow: "3px 3px 17px 2px #E6E5E5"}}>
                        <div>
                          <h5 style={{ color: "#121212BF" }}>
                            <FontAwesomeIcon icon={faUniversity} className="me-2" style={{ color: "#8E764F" }} />
                            Net Banking
                          </h5>
                        </div>
                        <Button
                          variant="outline-secondary"
                          style={{ backgroundColor: "#ffffff", border: "2px solid #8E1336", color: "#8E1336" }}
                          onClick={() => handleAddClick('net-banking')}
                        >
                          Add
                        </Button>
                      </Card>
                    </Col>
                    <Col md={12}>
                      <h2 className="py-2" style={{ fontSize: "24px", color: "#121212BF" }}>Cash</h2>
                      <Card className="p-3 d-flex flex-row justify-content-between align-items-center" style={{boxShadow: "3px 3px 17px 2px #E6E5E5"}}>
                        <div>
                          <h5 style={{ color: "#121212BF" }}>
                            <FontAwesomeIcon icon={faMoneyBillWave} className="me-2" style={{ color: "#8E764F" }} />
                            Cash on Delivery
                          </h5>
                        </div>
                        <Button
                          variant="outline-secondary"
                          style={{ backgroundColor: "#ffffff", border: "2px solid #8E1336", color: "#8E1336" }}
                          onClick={() => handleAddClick('cash-on-delivery')}
                        >
                          Add
                        </Button>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
