import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const MainImage = ({ src, alt }) => {
  return (
    <div
      className="w-100 d-flex flex-column justify-content-between position-relative"
      style={{ aspectRatio: "16/", overflow: "hidden" }}
    >
      <img
        src={src}
        alt={alt}
        className="img-fluid w-100"
        style={{ objectFit: "cover", height: "100%", width: "100%" }}
      />
    </div>
  );
};

const Thumbnail = ({ src, alt, onClick }) => {
  return (
    <div className="thumbnail-wrapper" style={{ aspectRatio: "4/", overflow: "hidden" }}>
      <img
        className="thumbnail-image img-fluid w-100 rounded"
        src={src}
        alt={alt}
        onClick={onClick}
        style={{ cursor: "pointer", objectFit: "cover", height: "100%", width: "100%" }}
      />
    </div>
  );
};

const Collection = ({
  title,
  mainImageSrc,
  mainImageAlt,
  thumbnails,
  buttonText,
}) => {
  const [selectedImage, setSelectedImage] = useState(mainImageSrc);

  return (
    <div className="container-fluid px-5 my-4" >
      <h2
        className="text-start mb-4 mt-5"
        style={{
          fontSize: "40px",
          fontWeight: "600",
          fontFamily: "Playfair Display",
          color: "#121212BF",
        }}
      >
        {title}
      </h2>
      <div className="row">
        <div className="col-lg-6">
          <MainImage src={selectedImage} alt={mainImageAlt} />
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-between">
          <div className="row row-cols-2 g-4">
            {thumbnails.map((thumbnail, index) => (
              <div className="col" key={index}>
                <Thumbnail
                  src={thumbnail.src}
                  alt={thumbnail.alt}
                  onClick={() => setSelectedImage(thumbnail.src)}
                />
              </div>
            ))}
          </div>
          {/* Button aligned at the bottom and centered */}
          <div className="d-flex justify-content-center align-items-center mt-4">
            <button
              className="btn px-4 py-2 text-white"
              style={{
                fontSize: "16px",
                fontWeight: "700",
                fontFamily: "Ubuntu, sans-serif",
                backgroundColor: "#8E1336",
              }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const bridalvideo =
    "https://shilohbridal.pmpframe.com/api/upload/bridalvideo.svg";
  const thumbnails = [
    {
      src: "https://shilohbridal.pmpframe.com/api/upload/Img3.svg",
      alt: "Bridal Dress 1",
    },
    {
      src: "https://shilohbridal.pmpframe.com/api/upload/img4.svg",
      alt: "Bridal Dress 2",
    },
    {
      src: "https://shilohbridal.pmpframe.com/api/upload/Img5.svg",
      alt: "Bridal Dress 3",
    },
    {
      src: "https://shilohbridal.pmpframe.com/api/upload/Img6.svg",
      alt: "Bridal Dress 4",
    },
  ];

  return (
    <Collection
      title="Bridal Collection"
      mainImageSrc={bridalvideo}
      mainImageAlt="Bridal Dress"
      thumbnails={thumbnails}
      buttonText="View All"
    />
  );
};

export default App;
