import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Container, Row, Col, Card, Button, Image, Form, InputGroup } from 'react-bootstrap';
import NavHeading from '../Navheading/NavHeading';
import NavBar from '../Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch, faTruck } from '@fortawesome/free-solid-svg-icons';

export default function Orders() {
  return (
    <div>
      <NavHeading />
      <NavBar />
      <Container fluid className="dashboard-container">
        <h1 className="py-4" style={{ textAlign: "center", color: "#121212BF" }}>My Account</h1>
        <Row className="dashboard">
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Row className="g-4 px-5">
              {/* All Orders Heading, Filter Button, and Search Input */}
              <Col md={12} className="d-flex justify-content-between align-items-center">
                <h2 style={{ color: "#121212BF" }}>All Orders</h2>
                <div className="d-flex align-items-center">
                  <InputGroup style={{ width: "276px", marginRight: "10px", marginTop: "14px" }}>
                    <InputGroup.Text style={{backgroundColor: "#ffffff", color: "#8E1336"}}>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Search Orders"
                      style={{ height: "43px" }}
                    />
                  </InputGroup>
                  <Button
                    variant="outline px-5 py-2"
                    style={{
                      backgroundColor: "#ffffff",
                      border: "2px solid #8E1336",
                      color: "#8E1336",
                      fontSize: "15px",
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} className="me-2" /> Filter
                  </Button>
                </div>
              </Col>

              {/* Order Card */}
              <Col md={12}>
                <Card className="d-flex p-4 px-5 shadow-sm">
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <h4 style={{ color: "#121212BF" }}><FontAwesomeIcon icon={faTruck} className="me-2" style={{color: "8E1336"}}/> On the way</h4>
                  </div>
                  <div className='px-5'>
                    <p style={{ color: "#121212BF" }}>Delivery by: Sunday, 01 October</p>
                  </div>

                  {/* Nested Product Card */}
                  <Card className="mt-3 p-3">
                    <div className="d-flex align-items-center">
                      <Image
                        src="https://shilohbridal.pmpframe.com/api/upload/hover-black.svg"
                        alt="Product Image"
                        style={{ width: '80px', height: '105px', objectFit: 'cover', marginRight: '15px' }}
                      />
                      <div>
                        <h6 style={{ color: "#121212BF" }}>Black Frill Gown</h6>
                        <p style={{ color: "#121212BF", margin: "0px" }}>#0011223</p>
                        <p style={{ color: "#121212BF" }}>Size : M</p>
                      </div>
                    </div>
                  </Card>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
