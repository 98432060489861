// Existing imports
import React, { useState } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Login() {
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const logo = "https://shilohbridal.pmpframe.com/api/upload/logo.svg";

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const isEmail = emailOrMobile.includes("@");
  
    const formData = new FormData();
    formData.append(isEmail ? "email" : "mobile", emailOrMobile);
    formData.append("password", password);
  
    try {
      const response = await fetch("https://shilohbridal.pmpframe.com/api/login.php", {
        method: "POST",
        body: formData,
      });
    
      console.log("Response Status:", response.status); // Log response status
      
      if (!response.ok) {
        const errorText = await response.text(); // Get error text for non-200 responses
        console.error("Error response:", errorText); // Log the error response for debugging
        setError(`Error: ${response.status} - ${errorText}`);
        return; // Stop further execution
      }
    
      const data = await response.json(); // Parse JSON if the content type is correct
      if (data.success) {
        alert("Login successful!");
        localStorage.setItem("user_id", data.id); // Store user ID
        navigate("/"); // Redirect
      } else {
        setError(data.msg || "Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Catch block error:", error);
      setError("An error occurred. Please try again later.");
    }
    
  };
  
  return (
    <div
      className="container-fluid d-flex align-items-center min-vh-100"
      style={{
        backgroundImage: "url('https://shilohbridal.pmpframe.com/api/upload/login_banner.jpeg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-12 col-sm-10 col-md-8 col-lg-6 bg-white p-4 p-md-5 rounded shadow">
            <div className="text-center mb-4">
              <img
                src={logo}
                className="img-fluid"
                alt="Shiloh Bridal Logo"
                style={{ maxWidth: "170px" }}
              />
            </div>
            <h2 className="text-center mb-4" style={{ fontSize: "29px" }}>
              Sign In
            </h2>
            {error && <p className="text-danger text-center">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="emailOrMobile" className="form-label">Email or Phone number</label>
                <input
                  type="text"
                  className="form-control"
                  id="emailOrMobile"
                  name="emailOrMobile"
                  value={emailOrMobile}
                  onChange={(e) => setEmailOrMobile(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberMe"
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Remember me
                  </label>
                </div>
                <Link to="/ForgotPassword" className="text-decoration-none">
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary w-100 mb-3"
                style={{ background: "#902637", border: "none" }}
              >
                Sign In
              </button>
              <div className="text-center mb-3">
                <h5>OR</h5>
                <div>
                  <Link
                    to="/"
                    className="btn mx-1"
                    style={{ backgroundColor: "#8F1336", color: "#ffffff" }}
                  >
                    <FaGoogle />
                  </Link>
                  <Link
                    to="/"
                    className="btn mx-1"
                    style={{ backgroundColor: "#8F1336", color: "#ffffff" }}
                  >
                    <FaFacebook />
                  </Link>
                </div>
              </div>
            </form>
            <p className="text-center mt-3">
              Don't have an account?{" "}
              <Link to="/Signup" className="text-decoration-none">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
