import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// Import required packages and components
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import pages and components for routing
import Home from './pages/Home';
import ShopByCategories from './pages/ShopByCategories';
import Product from './pages/Product';
import MyCart from './pages/MyCart';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import NewPassword from './components/ForgotPassword/NewPassword';
import Wishlist from './components/Wishlist/Wishlist';
import Account from './pages/Account';
import Address from './components/Address/Address';
import Orders from './components/Orders/Orders';
import PersonalDetails from './components/PersonalDetails/PersonalDetails';
import PaymentMethods from './components/Payment/PaymentMethods';
import CreditDebit from './components/Payment/CreditDebit';
import Upi from './components/Payment/Upi';
// import NotFound from './pages/NotFound'; 

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shopbycategories" element={<ShopByCategories />} />
        <Route path="/product/:id" element={<Product />} /> 
        <Route path="/MyCart" element={<MyCart />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/account" element={<Account />} />
        <Route path="/address" element={<Address />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/personal-details" element={<PersonalDetails />} />
        <Route path="/payment-methods" element={<PaymentMethods />} />
        <Route path="/credit-debit" element={<CreditDebit />} />
        <Route path="/upi" element={<Upi />} />
        {/* <Route path="*" element={<NotFound />} />  */}
      </Routes>
    </Router>
  );
}

export default App;
