import React from 'react';
import { FaPhone, FaEnvelope } from 'react-icons/fa'; 
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported

export default function ContactHeading() {
  return (
    <div className="d-flex justify-content-end align-items-center px-5 py-3 mt-3" style={{ backgroundColor: '#8E764F' }}>
      <div className="d-flex align-items-center gap-4">
        <h5 className="text-white m-0" style={{fontSize: "16px", fontFamily: "ubuntu", fontWeight: "700"}}>
          <FaPhone style={{ transform: 'rotate(100deg)', marginRight: '4px' }} /> 
          Toll-Free No: +123456789
        </h5>
        <h5 className="text-white m-0" style={{fontSize: "16px", fontFamily: "ubuntu", fontWeight: "700"}}>
          <FaEnvelope style={{ marginRight: '9px' }} /> 
          shilohbridal@gmail.com
        </h5>
      </div>
    </div>
  );
}
