import Footer from '../Footer/Footer';
import Banner from '../Banner/Banner';
import NavHeading from '../Navheading/NavHeading';
import NavBar from '../Navbar/Navbar';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

export default function Related() {
  const [hoveredCard, setHoveredCard] = useState(null); 
  const [cards, setCards] = useState([]); 

  useEffect(() => {
    const fetchWishlist = async () => {
      const formData = new FormData();
      formData.append('action', 'wishlist_list');
      formData.append('userid', 110); 

      const response = await fetch('https://shilohbridal.pmpframe.com/api/wishlist.php', {
        method: 'POST',
        body: formData,
      });
      
      const result = await response.json();
      
      if (result.success) {
        const formattedCards = result.data.map(item => ({
          img: item.url,
          hoverImg: item.url1 || item.url,
          title: item.name,
          price: `Rs.${item.price}`,
          rating: 4,
          discount: item.discount,
        }));

        setCards(formattedCards);
      }
    };

    fetchWishlist();
  }, []);

  const StarRating = ({ rating }) => {
    const totalStar = 5;
    const fullStar = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <div className="d-flex justify-content-center align-items-center">
        {[...Array(fullStar)].map((_, index) => (
          <FaStar key={index} />
        ))}
        {halfStar && <FaStarHalfAlt />}
        {[...Array(totalStar - fullStar - (halfStar ? 1 : 0))].map((_, index) => (
          <FaRegStar key={index} />
        ))}
        <p className="mb-0 ml-2" style={{ fontSize: 'larger', color: '#121212' }}>
          ( 18 )
        </p>
      </div>
    );
  };

  return (
    <div>
      <NavHeading />
      <NavBar />
      
      <div className="container mt-5">
        <h2 className="wishlist-heading text-center mb-4">My Wishlist</h2>
        <div className="row">
          {cards.map((item, index) => (
            <div className="col-md-3 mb-4" key={index}>
              <div className="card dress-card">
                <div
                  className="card-img-top position-relative"
                  onMouseEnter={() => setHoveredCard(index)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  <img
                    className="w-100"
                    src={hoveredCard === index ? item.hoverImg : item.img}
                    alt={item.title}
                    style={{ transition: 'opacity 0.3s ease' }}
                  />
                  <FontAwesomeIcon icon={faHeart} className="position-absolute heart-icon" style={{ top: '10px', right: '10px', color: 'red' }} />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">
                    <Link to="/Product" className="text-dark">{item.title}</Link>
                  </h5>
                  <p className="card-text">
                    <strike className="text-muted">Rs. {item.discount}</strike>
                    <span className="ml-2 font-weight-bold">{item.price}</span>
                  </p>
                  <StarRating rating={item.rating} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <Banner />
    </div>
  );
}
