import React from 'react';

import ResetPassword from '../components/ForgotPassword/PasswordReset';

export default function ForgotPassword() {
  return (
    <div>
    
     <ResetPassword />
      
    </div>
  )
}
